<template>
  <div id="app">
    <Topbar />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Topbar from "@/components/Topbar.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Topbar,
    Footer
  }
};
</script>

<style>
html,
body {
  background-color: #fff;
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, Helvetica, Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

audio {
  display: none !important;
}

html,
body {
  line-height: 1.4;
  color: #000;
}

html,
body,
#app {
  height: 100%;
}

#app {
  display: flex;
  flex-flow: column nowrap;
}

#app > div {
  flex-grow: 1;
}

.noscroll {
  overflow: hidden !important;
}

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  display: none;
  width: 0;
  background: rgba(0, 0, 0, 0);
}

input,
button,
textarea {
  font-family: inherit;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: 0 none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

ul {
  list-style: none;
}

b,
strong {
  font-weight: 500;
}

hr {
  background-color: rgba(130, 130, 130, 0.3);
  border: 0 none;
  display: block;
  height: 1px;
  margin: 0.5rem 0;
  width: 100%;
}

svg {
  display: block;
}

.wrapper {
  margin: 0 auto;
  max-width: 1200px;
  position: relative;
  width: 100%;
}
</style>
