<template>
  <footer>
    <div class="wrapper">
      <div class="copyright">
        {{ new Date().getFullYear() }} © 7GAMES. All rights reserved.
      </div>
      <nav>
        <router-link to="/privacy-policy">Privacy Policy</router-link>
        <router-link to="/terms-of-service">Terms of Service</router-link>
      </nav>
    </div>
  </footer>
</template>

<script>
export default {
  name: "footer_website"
};
</script>

<style scoped>
footer {
  align-items: center;
  background-color: #000;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  display: flex;
  height: 160px;
  min-height: 160px;
  padding: 40px 0;
  width: 100%;
}

footer > .wrapper {
  align-items: center;
  display: flex;
}

footer .copyright {
  flex-grow: 1;
}

footer nav {
  display: flex;
}

footer nav a {
  transition: color 0.3s;
  color: #fff;
  font-size: 1em;
  font-weight: 500;
  margin: 0 16px;
  text-decoration: none;
}

footer nav a:hover {
  color: rgba(255, 255, 255, 0.5);
}

@media only screen and (max-width: 650px) {
  footer {
    height: auto;
    min-height: auto;
    padding: 30px 0;
  }

  footer .wrapper {
    flex-flow: column-reverse nowrap;
  }

  footer .copyright {
    margin: 10px 0 0;
  }
}
</style>
