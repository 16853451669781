import Vue from "vue";
import VueRouter from "vue-router";
import Meta from "vue-meta";

import Home from "../views/Home.vue";
import Privacy from "../views/Privacy.vue";
import Terms from "../views/Terms.vue";

Vue.use(VueRouter);
Vue.use(Meta);

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes: [
		{
			path: "/",
			name: "Home",
			component: Home
		},
		{
			path: "/privacy-policy",
			name: "Privacy",
			component: Privacy
		},
		{
			path: "/terms-of-service",
			name: "Terms",
			component: Terms
		},
		{
			path: "*",
			redirect: "/"
		}
	],
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return { x: 0, y: 0 };
		}
	}
});

export default router;
