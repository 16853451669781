<template>
  <div class="main">
    <section id="about">
      <div class="wrapper">
        <div class="pulse">
          <div class="logo" id="logo3d"></div>
        </div>
        <div class="content">
          <h1>About us</h1>
          <p>
            7games is a content and HTML5 games development company founded in
            December 2020 by Fernando, with 25 employees working in offices
            located in São Paulo, Teresina, and Recife, Brazil. The founder had
            worked for several years with game development before starting the
            company.
          </p>
          <p>
            Using viral content and high sharing rate, Space Puzzle was 7games
            first big success case.
          </p>
          <p>
            Since then, following the same business model, 3 more products have
            emerged that together achieve expressive results on a daily basis.
          </p>
          <p>
            The company continues to invest mainly in experimenting with viral
            content and in the development of more HTML5 games as well as mobile
            applications such as Signal.
          </p>
        </div>
      </div>
    </section>
    <section id="games">
      <div class="wrapper">
        <h1>Our Games</h1>
        <div class="list">
          <div class="item" v-for="item in games" :key="item.title">
            <div class="wrap">
              <div class="cover">
                <img :src="item.cover" :alt="item.title" />
              </div>
              <div class="content">
                <div class="icon">
                  <img :src="item.icon" :alt="item.title" />
                </div>
                <div class="details">
                  <h3 class="title">{{ item.title }}</h3>
                  <a class="btn" :href="item.play" target="_blank">Play</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="contact">
      <div class="wrapper">
        <div class="contact-wrapper">
          <div class="form">
            <h1>Get in touch</h1>
            <p>
              You may reach us through this form or you may contact us at
              <a href="mailto:contact@7games.io">contact@7games.io</a>. We'll
              get back to you shortly.
            </p>

            <br />

            <form action="/" method="post">
              <p>
                <input
                  autocomplete="off"
                  type="text"
                  id="contact.name"
                  name="name"
                  placeholder="Name"
                  required
                />
              </p>
              <p>
                <input
                  autocomplete="off"
                  type="text"
                  id="contact.company"
                  name="company"
                  placeholder="Company"
                  required
                />
              </p>
              <p>
                <input
                  autocomplete="off"
                  type="text"
                  id="contact.email"
                  name="email"
                  placeholder="Email"
                  required
                />
              </p>
              <p>
                <textarea
                  id="contact.content"
                  name="content"
                  placeholder="Message"
                  required
                ></textarea>
              </p>
              <p><button type="submit">Send</button></p>
            </form>
          </div>

          <div class="details">
            <h3>7GAMES STUDIO LTDA.</h3>
            <p>
              <strong>Address:</strong>
              <span
                >Av. Paulista, 807, Sala CJ2315 - Bela Vista, São Paulo,
                Brazil</span
              >
            </p>
            <p><strong>Email:</strong><span>contact@7games.io</span></p>
            <p><strong>Phone:</strong><span>+55 (11) 3230-6029</span></p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Home",
  metaInfo: {
    title: "7games"
  },
  data() {
    return {
      games: [
        {
          title: "Clash of Cats: Battle Arena",
          icon: require("../assets/icon_clashofcats.jpg"),
          cover: require("../assets/cover-clashofcats.png"),
          play: "https://fb.gg/play/clash-of-cats"
        },
        {
          title: "Cat Gunner: Super Force",
          icon: require("../assets/icon_catgunner.jpg"),
          cover: require("../assets/cover-catgunner.png"),
          play: "https://fb.gg/play/cat-gunner"
        },
        {
          title: "Space Gunners: Galaxy Shooter",
          icon: require("../assets/icon_spacegunners.jpg"),
          cover: require("../assets/cover-spacegunners.png"),
          play: "https://fb.gg/play/space-gunners-oig"
        },
        // {
        //   title: "137 Puzzle",
        //   icon: require("../assets/icon_137puzzle.jpg"),
        //   cover: require("../assets/cover-137puzzle.png"),
        //   play: "https://fb.gg/play/daypuzzle"
        // },
        {
          title: "Space Puzzle",
          icon: require("../assets/icon_spacepuzzle.png"),
          cover: require("../assets/cover-spacepuzzle.png"),
          play: "https://fb.gg/play/spacepuzzles"
        }
      ]
    };
  },
  computed: {
    isMobile() {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
      ];

      return toMatch.some(toMatchItem => {
        return navigator.userAgent.match(toMatchItem);
      });
    }
  },
  mounted() {
    let constrain = 150;
    let mouseOverContainer = document.getElementById("app");
    let ex1Layer = document.getElementById("logo3d");

    function transforms(x, y, el) {
      let box = el.getBoundingClientRect();
      let calcX = -(y - box.y - box.height / 2) / constrain;
      let calcY = (x - box.x - box.width / 2) / constrain;

      return (
        "perspective(100px) " +
        "   rotateX(" +
        calcX +
        "deg) " +
        "   rotateY(" +
        calcY +
        "deg) "
      );
    }

    function transformElement(el, xyEl) {
      el.style.transform = transforms.apply(null, xyEl);
    }

    if (!this.isMobile) {
      mouseOverContainer.onmousemove = function(e) {
        let xy = [e.clientX, e.clientY];
        let position = xy.concat([ex1Layer]);

        window.requestAnimationFrame(function() {
          transformElement(ex1Layer, position);
        });
      };
    }
  }
};
</script>

<style scoped>
.main > section {
  padding-top: 60px;
  position: relative;
  width: 100%;
}

#about {
  background: #fff url("../assets/background-about.jpg") no-repeat center bottom /
    contain;
  height: 100vh;
  min-height: 700px;
  padding: 120px 0 60px 0;
}

#about > .wrapper {
  display: flex;
  flex-flow: row nowrap;
  height: 80%;
}

#about .pulse {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  position: relative;
  width: 100%;
}

#about .pulse .logo {
  background: url("../assets/logo.png") no-repeat center center / contain;
  height: 260px;
  width: 260px;
  z-index: 6;
}

#about .content {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  font-size: 1.15em;
  padding: 0 20px;
  position: relative;
  min-width: 600px;
  width: 600px;
  z-index: 10;
}

#about .content p {
  margin: 1em 0;
}

@media only screen and (max-width: 650px) {
  #about {
    height: auto;
    padding: 80px 0 60px 0;
    min-height: auto;
  }

  #about > .wrapper {
    flex-flow: column nowrap;
  }

  #about .pulse {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    position: relative;
    width: 100%;
  }

  #about .pulse .logo {
    height: 140px;
    margin-bottom: 1em;
    width: 140px;
  }

  #about .content {
    font-size: 16px;
    text-align: center;
    min-width: 100%;
    width: 100%;
  }
}

#games {
  background: #0b1023;
  color: #fff;
  padding: 100px 0;
}

#games > .wrapper {
  text-align: center;
  max-width: 1600px;
}

#games h1,
#contact h1 {
  display: inline-block;
  font-size: 3em;
  font-weight: 400;
  margin-bottom: 1.25em;
  position: relative;
  text-transform: uppercase;
}

#games h1:after,
#contact h1:after {
  background-color: #007bff;
  bottom: 0;
  content: "";
  left: 0;
  display: inline-block;
  position: absolute;
  height: 2px;
  width: 100%;
}

#games .list {
  align-items: stretch;
  display: flex;
  flex-flow: row wrap;
  text-align: left;
  width: 100%;
}

#games .list .item {
  padding: 8px;
  width: 25%;
}

@media only screen and (max-width: 1024px) {
  #games .list .item {
    width: 50%;
  }
}

@media only screen and (max-width: 650px) {
  #games {
    padding: 80px 0 0px;
  }

  #games h1,
  #contact h1 {
    font-size: 1.75em;
    margin-bottom: 1em;
  }

  #games .list .item {
    width: 100%;
  }
}

#games .list .item .wrap {
  transition: transform 0.3s;

  background-color: #000;
  border-radius: 4px;
  display: flex;
  height: 100%;
  flex-flow: column nowrap;
}

#games .list .item .wrap:hover {
  transform: scale(1.05);
}

#games .list .item .cover img {
  border-radius: 4px 4px 0 0;
  display: block;
  pointer-events: none;
  width: 100%;
}

#games .list .item .content {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  padding: 20px;
}

#games .list .item .content .title {
  font-size: 1.2em;
  font-weight: 500;
  line-height: normal;
}

#games .list .item .content .icon {
  margin-right: 15px;
}

#games .list .item .content .icon img {
  display: block;
  border-radius: 8px;
  height: 100px;
  pointer-events: none;
  width: 100px;
}

@media only screen and (max-width: 1300px) {
  #games .list .item .content .title {
    font-size: 1em;
  }

  #games .list .item .content .icon img {
    height: 64px;
    width: 64px;
  }
}

#games .list .item .content .btn {
  background-color: #007bff;
  color: #fff;
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 1.15em;
  font-weight: 500;
  height: 42px;
  justify-content: center;
  margin-top: 10px;
  padding: 0 16px;
  text-align: center;
  text-decoration: none;
}

#contact {
  background: #000;
  color: #fff;
  padding: 100px 0 80px;
}

#contact h1 {
  font-size: 2.5em;
  margin: 0 0 15px;
}

#contact .contact-wrapper {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
}

#contact a {
  color: #fff;
  text-decoration: none;
}

#contact a:hover {
  text-decoration: underline;
}

#contact .contact-wrapper > div {
  width: 50%;
}

#contact .details {
  padding: 0 40px;
}

#contact .details h3 {
  font-size: 1.375em;
}

#contact .details p {
  margin: 10px 0;
}

#contact .details span {
  user-select: text !important;
}

#contact .details strong {
  display: inline-block;
  margin-right: 8px;
}

@media only screen and (max-width: 650px) {
  #contact {
    padding-top: 80px;
    padding-bottom: 30px;
  }

  #contact h1 {
    font-size: 1.75em;
  }

  #contact .contact-wrapper {
    flex-flow: column wrap;
    padding: 0 15px;
  }

  #contact .contact-wrapper > div {
    width: 100%;
  }

  #contact .details {
    margin-top: 2em;
    padding: 0;
  }
}

input,
textarea {
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  font-size: 1.5em;
  display: block;
  margin: 15px 0;
  padding: 0 10px;
  width: 100%;
}

input {
  height: 60px;
}

textarea {
  resize: none;
  padding: 10px;
  height: 200px;
  max-height: 200px;
}

button {
  align-items: center;
  background-color: #007bff;
  border: 0 none;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-size: 1.15em;
  font-weight: 500;
  height: 48px;
  justify-content: center;
  padding: 0 30px;
  text-align: center;
}
</style>
