<template>
  <header
    :class="{ elevated: top > 0, games: view.games, contact: view.contact }"
  >
    <div @click="home" class="logo">
      <svg
        width="26"
        height="30"
        viewBox="0 0 26 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.7707 29.6396C12.7822 29.7635 12.7936 29.8874 12.7936 30H4.47775C6.94508 23.1532 12.7022 10.7658 15.7635 4.5045H5.75711C2.28457 4.5045 0.776753 6.26126 0 7.56757L1.00521 0H23.5767L17.7739 12.3874C15.1695 17.973 12.7479 23.6036 12.7479 29.2793C12.7479 29.3919 12.7593 29.5158 12.7707 29.6396ZM15.0233 30H14.3426C14.3426 29.8534 14.3285 29.7008 14.3179 29.5863L14.3167 29.5729C14.3045 29.441 14.2969 29.3521 14.2969 29.2793C14.2969 23.7875 16.643 18.2965 19.2543 12.6959L19.2547 12.6951L25.2017 0H25.8065L20.0037 12.3874C17.3993 17.973 14.9776 23.6036 14.9776 29.2793C14.9776 29.3919 14.9891 29.5158 15.0005 29.6396C15.0119 29.7635 15.0233 29.8874 15.0233 30Z"
          fill="currentColor"
        />
      </svg>
    </div>
    <nav>
      <a @click="go('about')" :class="{ active: view.about }" href="#about"
        >About</a
      >
      <a @click="go('games')" :class="{ active: view.games }" href="#games"
        >Games</a
      >
      <a
        @click="go('contact')"
        :class="{ active: view.contact }"
        href="#contact"
        >Contact</a
      >
    </nav>
  </header>
</template>

<script>
export default {
  name: "topbar",
  data() {
    return {
      top: 0,
      view: {
        about: !1,
        games: !1,
        contact: !1
      }
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    if (this.$route.name === "Home") this.view.about = !0;

    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener("click", function(e) {
        e.preventDefault();

        document.querySelector(this.getAttribute("href")).scrollIntoView({
          behavior: "smooth"
        });
      });
    });

    this.handleSections();
  },
  watch: {
    $route({ name }) {
      if (name !== "Home")
        (this.view.about = !1),
          (this.view.games = !1),
          (this.view.contact = !1);
    }
  },
  methods: {
    home() {
      if (this.$route.name === "Home")
        window.scrollTo({ top: 0, behavior: "smooth" });
      else this.$router.push("/");
    },
    go(e) {
      if (this.$route.name !== "Home") {
        this.$router.push("/").then(() => {
          this.view[e] = !0;
          document.querySelector(`#${e}`).scrollIntoView({
            behavior: "smooth"
          });
        });
      } else {
        document.querySelector(`#${e}`).scrollIntoView({
          behavior: "smooth"
        });
      }
    },
    handleScroll() {
      (this.top = window.scrollY), this.handleSections();
    },
    handleSections() {
      const sections = document.querySelectorAll("section");

      sections.forEach(t => {
        this.view[t.id] =
          t.offsetTop - 60 === this.top ||
          (t.offsetTop - 60 < this.top &&
            t.offsetTop - 60 + t.offsetHeight > this.top);
      });
    }
  }
};
</script>

<style scoped>
header {
  transition: background 0.2s, color 0.2s, border 0.2s, box-shadow 0.2s;

  align-items: center;
  background-color: #fff;
  color: #000;
  border-bottom: 1px solid transparent;
  display: flex;
  flex-flow: row nowrap;
  left: 0;
  height: 60px;
  padding: 0 12px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

header.elevated {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  /* box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05); */
}

header.games {
  background-color: #0b1023;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
}

header.contact {
  background-color: #000;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
}

header .logo {
  cursor: pointer;
  height: 30px;
  width: 30px;
}

header .logo svg {
  transition: fill 0.2s;

  fill: currentColor;
}

header > nav {
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  justify-content: center;
  margin: 0 10px;
}

header > nav a {
  /* transition: color 0.2s; */

  font-weight: 500;
  display: block;
  color: currentColor;
  text-decoration: none;
  white-space: nowrap;
  margin: 0 20px;
}

header > nav a.active {
  color: #007bff;
}
</style>
